import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['template', 'list']

  addNestedFields(event) {
    event.preventDefault();
    this.listTarget.insertAdjacentHTML('beforeend', this.newAssociation)
  }

  get newAssociation() {
    return this.templateTarget.innerHTML.replace(/NEW_RECORD/g, this.newID)
  }

  get newID() {
    return new Date().getTime();
  }
}
