import { Controller } from "stimulus"

// This controller overrides the form method because
// overridng the method with formmethod doesn't work
// Rails uses a hidden input to set the method to :delete or :patch
// Therefore to override it to :post we need to update that hidden field.

// Use case: for a form with multiple submit buttons targetting different routes.
export default class extends Controller {
  static targets = ['form'];

  post(event) {
    this._formMethod = 'post'
  }

  get(event) {
    this._formMethod = 'get'
  }

  set _formMethod(value) {
    this.formTarget.querySelector('input[name=_method]').value = value
  }
}