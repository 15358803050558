import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['icon', 'content'];
  static classes = ['content', 'showIcon', 'hideIcon'];
  static values  = { active: Boolean }

  activeValueChanged() {
    this.display()
  }

  toggleContent(event) {
    event.preventDefault()
    this.activeValue = !this.activeValue
  }

  display() {
    this.activeValue ? this.show() : this.hide()
  }

  show() {
    this.contentTarget.classList.remove(this.contentClass)
    this.iconTarget.classList.remove(this.showIconClass)
    this.iconTarget.classList.add(this.hideIconClass)
  }

  hide() {
    this.contentTarget.classList.add(this.contentClass)
    this.iconTarget.classList.add(this.showIconClass)
    this.iconTarget.classList.remove(this.hideIconClass)
  }
}
