import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ['content'];
  static values  = { showComment: Boolean }
  static targets = ['radio']

  showCommentValueChanged() {
    this.displayComment()
  }

  display(event) {
    event.preventDefault()
    this.showCommentValue = this.isDisapproved
  }

  get isDisapproved() {
    return this.radioTargets
      .filter(item => item.value == 'disapproved')
      .filter(item => item.checked)
      .length === 1
  }

  displayComment() {
    this.showCommentValue ? this._showComment() : this._hideComment()
  }
}
