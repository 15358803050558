import { Controller } from "stimulus"
import CommentController from "./comment_controller.js"

export default class extends CommentController {
  static targets = ['approvedComment', 'disapprovedComment'];

  _hideComment() {
    this.approvedCommentTarget.classList.remove(this.contentClass)
    this.disapprovedCommentTarget.classList.add(this.contentClass)
  }

  _showComment() {
    this.disapprovedCommentTarget.classList.remove(this.contentClass)
    this.approvedCommentTarget.classList.add(this.contentClass)
  }
}
