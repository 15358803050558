import { Controller } from "stimulus"

// change height if dropdown height is too small to display all items on mobile.
export default class extends Controller {
  static targets = ["menu"];
  static classes = ["collapsed"];
  static values =  { maxHeight: String, isCollapsed: Boolean };

  isCollapsedValueChanged() {
    this.setMaxHeight()
  }

  setMaxHeight() {
    this.isCollapsedValue ? this._collapse() : this._expand()
  }

  toggle(event) {
    event.preventDefault()
    this.isCollapsedValue = !this.isCollapsedValue
  }

  _expand() {
    this.menuTarget.style.maxHeight = this.maxHeightValue
  }

  _collapse() {
    this.menuTarget.style.maxHeight = 0
  }
}