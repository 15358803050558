import { Controller } from "stimulus"
import StatusController from "./status_controller.js"

export default class extends StatusController {
  displayAssessmentStatus() {
    this.updateAssessedMessage()
    this.hideMessages()

    if (this.noMessages) return

    if (this.isUnassessed) this.showMessage(this.unassessedMessageTarget)
    else                   this.showMessage(this.assessedMessageTarget)
  }

  get noMessages() {
    return (
      !this.hasUnassessedMessageTarget ||
      !this.hasAssessedMessageTarget
    )
  }
}
