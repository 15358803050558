$.fn.val_or_text = (args...) ->
  if this.first().is('input, select, textarea')
    this.val.apply(this, args)
  else
    this.text.apply(this, args)

$.fn.conditional = (action) ->
  $(this).filter(':not(.conditional_' + action + ')').each ->
    $this = $(this).addClass('conditional_' + action)
    $this_parent = $this.parent()
    $if = $('#' + $this.data('if-id') )
    test_and_do = ->
      condition_met = false
      if $this.is('[type="checkbox"], [type="radio"]')
        condition_met = $this.is(':checked')
      else if $this.data('if-value-in')?
        options = ('' + $this.data('if-value-in')).split(',')
        condition_met = $.inArray($if.val(), options) != -1
      else if $this.data('if-value')?
        condition_met = "#{$this.data('if-value')}" == "#{$if.val()}"
      else
        condition_met = $if.val()? &&
        $if.val() != '' &&
        $if.val() != 'false' &&
        $if.val() != '0' &&
        $if.val() != '$0.00'
      switch action
        when 'classify' then $this.toggleClass($this.data('if-class'), condition_met)
        when 'hide' then $this.toggle(!condition_met)
        when 'show' then $this.toggle(condition_met)
        when 'select'
          if $this.closest('select').find('option[selected]').length == 0
            $this.closest('select').val($this.attr('value') || $this.text())
        when 'check'
          $this.prop('checked', condition_met);
          $this.change();
        when 'disable' then $this.add($this.find(':input')).prop('disabled', condition_met)
        when 'disable-unless' then $this.add($this.find(':input')).prop('disabled', !condition_met)
        when 'append'
          if condition_met and $this.parent().length == 0
            $this.appendTo($this_parent)
          else if !condition_met and $this.parent().length > 0
            $this.detach()
        when 'empty-unless'
          unless condition_met
            $this.val_or_text('')
            $this.change()
        when 'empty'
          if condition_met
            $this.val_or_text('')
            $this.change()
    $if.bind('change', test_and_do)
    test_and_do()

$.initialize_conditionals = ->
  $('.hide-if').conditional('hide')
  $('.show-if').conditional('show')
  $('.select-if').conditional('select')
  $('.check-if').conditional('check')
  $('.disable-if').conditional('disable')
  $('.disable-unless').conditional('disable-unless')
  $('.empty-if').conditional('empty')
  $('.empty-unless').conditional('empty-unless')
  $('.append-if').conditional('append')
  $('.classify-if').conditional('classify')
