import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['section', 'tab'];
  static classes = ['section', 'tab'];
  static values  = { index: String }

  indexValueChanged() {
    this.showCurrent()
  }

  setCurrent(event) {
    event.preventDefault();
    this.indexValue = event.currentTarget.dataset.index
  }

  showCurrent() {
    this.showCurrentSection()
    this.showCurrentTab()
  }

  showCurrentSection() {
    this.sectionTargets.forEach(element => this.updateSectionDisplay(element))
  }

  showCurrentTab() {
    this.tabTargets.forEach(element => this.updateTabDisplay(element))
  }

  updateTabDisplay(element) {
    this.updateDisplay(element, this.tabClass)
  }

  updateSectionDisplay(element) {
    this.updateDisplay(element, this.sectionClass)
  }

  updateDisplay(element, cssClass) {
    element.classList.toggle(cssClass, this.indexValue == element.dataset.index)
  }
}