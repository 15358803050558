import { Controller } from "stimulus"
import CommentController from "./comment_controller.js"

export default class extends CommentController {
  static targets = ['comment'];

  _hideComment() {
    this.commentTarget.classList.add(this.contentClass)
  }

  _showComment() {
    this.commentTarget.classList.remove(this.contentClass)
  }
}
