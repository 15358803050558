import { Controller } from "stimulus"
import StatusController from "./status_controller.js"

export default class extends StatusController {
  displayAssessmentStatus() {
    this.updateAssessedMessage()
    this.hideMessages()

    if(this.noMessages) return

    if      (this.isUnassessed)  this.showMessage(this.unassessedMessageTarget)
    else if (this.isDisapproved) this.showMessage(this.disapprovedMessageTarget)
    else                         this.showMessage(this.approvedMessageTarget)
  }

  get noMessages() {
    return (
      !this.hasUnassessedMessageTarget ||
      !this.hasDisapprovedMessageTarget ||
      !this.hasApprovedMessageTarget
    )
  }
}
