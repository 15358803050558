import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'input'];
  static values = { isEnabled: Boolean };

  isEnabledValueChanged() {
    this.inputTarget.disabled = !this.isEnabledValue
    this.checkboxTarget.checked = this.isEnabledValue
  }

  update() {
    this.isEnabledValue = !this.isEnabledValue
  }
}
