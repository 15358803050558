import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.initialiseTooltips()
  }

  initialiseTooltips() {
    $('[data-toggle="tooltip"]').tooltip();
  }
}
