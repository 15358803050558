/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery

import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "@rails/ujs";

Rails.start();

// require("turbolinks").start() // TODO
require("@rails/activestorage").start();
require("trix");
require("@rails/actiontext");
require("../plugins/chosen.js");
require("../plugins/conditional.js.coffee");

import "controllers";

window.jQuery = jQuery;

// require("../plugins/bootstrap.min.js") // TODO: Need to confirm - but I think this is being imported multiple times - commenting out here for now. It was playing havoc with some of the data-actions (e.g. modals)
require("../plugins/chosen.js");
require("../plugins/conditional.js.coffee");

import "controllers";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
