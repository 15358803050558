import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['radio', 'message', 'assessedMessage', 'unassessedMessage', 'disapprovedMessage', 'approvedMessage']
  static classes = ['inactive']
  static values  = { unassessedCount: Number, disapprovedCount: Number }

  unassessedCountValueChanged() {
    this.displayAssessmentStatus()
  }

  disapprovedCountValueChanged() {
    this.displayAssessmentStatus()
  }

  updateAssessmentStatus(event) {
    event.preventDefault()

    this.unassessedCountValue  = this.itemNumberOf('unassessed')
    this.disapprovedCountValue = this.itemNumberOf('disapproved')
  }

  get isUnassessed() {
    return this.unassessedCountValue > 0
  }

  get isDisapproved() {
    return this.isAssessed && this.disapprovedCountValue > 0
  }

  get isApproved() {
    return this.isAssessed && !this.isDisapproved
  }

  get isAssessed() {
    return !this.isUnassessed
  }

  hideMessages() {
    this.messageTargets.forEach(element => element.classList.add(this.inactiveClass))
  }

  showMessage(target) {
    target.classList.remove(this.inactiveClass)
  }

  itemNumberOf(score) {
    return this.radioTargets
      .filter(item => item.value == score)
      .filter(item => item.checked)
      .length
  }

  updateAssessedMessage(){
    if( this.hasUnassessedMessageTarget) {
      this.unassessedMessageTarget.innerText = `You have ${this.unassessedCountValue} unassessed ${this._pluralizeDomain()}`
    }
  }

  _pluralizeDomain() {
    return this.unassessedCountValue > 1 ? 'domains' : 'domain'
  }
}
